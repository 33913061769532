// pages/AboutPage.js
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import BackgroundLayout from '../components/BackgroundLayout';

function AboutPage() {
  return (
    <BackgroundLayout>
      <Row className="justify-content-center">
        <Col md={10} lg={8}>
          <div className="py-5 px-4 bg-light bg-opacity-75 rounded shadow">
            <h3 className="text-center mb-4">O projekcie</h3>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Projekt25 – „fotograficzne wieloświaty"</Card.Title>
                <Card.Text>
	  „Fotograficzne wieloświaty" to projekt
fotograficzny w formule warsztatowej, który
zachęca do odkrycia mocy obrazu jako narzędzia
twórczej ekspresji i samorozwoju. Jestem
przekonany, że fotografia to znacznie więcej niż
tylko mechaniczny akt rejestracji rzeczywistości –
to potężne narzędzie do kreowania, interpretacji
i komunikacji naszego unikalnego, osobistego
postrzegania świata.
Partnerem i współorganizatorem warsztarów jest
drukarnia artystyczna <a className="my-link" target="_blank" href="https://qprint.pl/">QPrint</a>,
	  adres: <a className="my-link" target="_blank" href="https://maps.app.goo.gl/MvVZEDjstQsat2hp7">Warszawa, ul. Wolska 44 lok. U1</a>.
                </Card.Text>
              </Card.Body>
            </Card>

            <h3 className="text-center mb-4">Polecane narzędzia</h3>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Sprzęt i aplikacje</Card.Title>
                <Card.Text>
Do pracy na zajęciach potrzebny będzie sprzęt fotograficzny.
Można używać dowolnego aparatu cyfrowego lub smartfona. Wszystkie
zadania i ćwiczenia będzie można wykonać używając wyłącznie smartfona.
	 Niektóre ćwiczenia w czasie zajęć będziemy wykonywać korzystając
	  ze smartfonów. Do realizacji zadań użyteczne będą aplikacje
	  dające większe możliwości sterowania wbudowanym aparatem
	  oraz funkcje pozwalające na szybki postprocesing zdjęć.
                </Card.Text>

                <h5 className="mb-3">Dla iPhone:</h5>
                <ul className="list-group mb-4">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span>Snapseed - edycja zdjęć</span>
                    <a href="https://apps.apple.com/us/app/snapseed/id439438619"
                      target="_blank" rel="noopener noreferrer"
                      className="btn btn-sm btn-outline-primary">Pobierz</a>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span>Slow Shutter Cam - długie ekspozycje</span>
                    <a href="https://apps.apple.com/pl/app/slow-shutter-cam/id357404131?l=pl"
                      target="_blank" rel="noopener noreferrer"
                      className="btn btn-sm btn-outline-primary">Pobierz</a>
                  </li>
                </ul>

                <h5 className="mb-3">Dla Android:</h5>
                <ul className="list-group mb-4">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span>Snapseed - edycja zdjęć</span>
                    <a href="https://play.google.com/store/apps/details?id=com.niksoftware.snapseed&pcampaignid=web_share"
                      target="_blank" rel="noopener noreferrer"
                      className="btn btn-sm btn-outline-primary">Pobierz</a>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span>HD Camera 2025 - zaawansowany aparat</span>
                    <a href="https://play.google.com/store/apps/details?id=com.risingcabbage.hd.camera&pcampaignid=web_share"
                      target="_blank" rel="noopener noreferrer"
                      className="btn btn-sm btn-outline-primary">Pobierz</a>
                  </li>
                </ul>
              </Card.Body>
            </Card>

          </div>
        </Col>
      </Row>
    </BackgroundLayout>
  );
}

export default AboutPage;
