// src/pages/TasksPage.js
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Alert, Spinner, Accordion, Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import FileUploadModal from '../components/FileUploadModal';
import PhotoGallery from '../components/PhotoGallery';
import BackgroundLayout from '../components/BackgroundLayout';
import logo from '../assets/p25-200.png';

function TasksPage() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userPhotos, setUserPhotos] = useState({});
  const [photosLoading, setPhotosLoading] = useState(false);
  const [photosError, setPhotosError] = useState(null);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const { userEmail, isAuthenticated, confirmAuth } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  // Stan dla modalu uploadowania plików
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);

  // Stan dla akordeonów zadań - początkowo wszystkie zamknięte
  const [activeKey, setActiveKey] = useState(null);

  // Sprawdzenie parametrów URL dla logowania przez email
  useEffect(() => {
    // Parsowanie parametrów URL
    const searchParams = new URLSearchParams(location.search);
    const loginSuccess = searchParams.get('login_success');
    const email = searchParams.get('email');

    if (loginSuccess === 'true' && email) {
      // Logowanie użytkownika na podstawie parametrów z URL
      confirmAuth(email);
      setLoginSuccess(true);

      // Usunięcie parametrów z URL po zalogowaniu
      setTimeout(() => {
        navigate('/tasks', { replace: true });
      }, 3000);
    }
  }, [location, confirmAuth, navigate]);

  // Przekierowanie niezalogowanych użytkowników
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    }
  }, [isAuthenticated, navigate]);

  // Pobieranie zadań z API
  useEffect(() => {
    const fetchTasks = async () => {
      // Nie pobieraj zadań, jeśli użytkownik nie jest zalogowany
      if (!isAuthenticated) return;

      try {
        const response = await fetch('/api/tasks');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const fetchedTasks = data.tasks || [];
        setTasks(fetchedTasks);

        // Ustaw pierwsze zadanie jako aktywne, jeśli istnieje
        if (fetchedTasks.length > 0) {
          setActiveKey(fetchedTasks[0].id);
        }
      } catch (err) {
        console.error('Błąd pobierania zadań:', err);
        setError('Nie udało się pobrać listy zadań. Spróbuj ponownie później.');
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [isAuthenticated]);

  // Pobieranie zdjęć użytkownika
  useEffect(() => {
    const fetchUserPhotos = async () => {
      if (!isAuthenticated || !userEmail) return;

      try {
        setPhotosLoading(true);
        setPhotosError(null);

        // Poprawione kodowanie emaila w URL
        const encodedEmail = encodeURIComponent(userEmail);
        console.log(`Pobieranie zdjęć dla użytkownika: ${encodedEmail}`);

        const response = await fetch(`/api/user-photos?email=${encodedEmail}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Otrzymane dane zdjęć:', data);
        setUserPhotos(data.photos || {});
      } catch (err) {
        console.error('Błąd pobierania zdjęć użytkownika:', err);
        setPhotosError('Nie udało się pobrać zdjęć. Spróbuj odświeżyć stronę.');
      } finally {
        setPhotosLoading(false);
      }
    };

    fetchUserPhotos();
  }, [isAuthenticated, userEmail]);

  // Obsługa sukcesu uploadu z modalu
  const handleUploadSuccess = (taskId, uploadedFiles) => {
    setUserPhotos(prev => ({
      ...prev,
      [taskId]: [...(prev[taskId] || []), ...uploadedFiles]
    }));

    // Otwórz zadanie, do którego zostały dodane zdjęcia
    setActiveKey(taskId);
  };

  // Otwieranie modalu do uploadu
  const openUploadModal = (taskId) => {
    setCurrentTaskId(taskId);
    setShowUploadModal(true);
  };

  // Formatowanie daty
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('pl-PL', options);
  };

  // Usuwanie zdjęcia
  const handleDeletePhoto = async (taskId, photoIndex) => {
    if (!isAuthenticated || !userEmail) return;

    try {
      const photoToDelete = userPhotos[taskId][photoIndex];
      console.log(`Usuwanie zdjęcia: ${photoToDelete.path}`);

      const response = await fetch('/api/delete-photo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          taskId,
          userEmail,
          photoPath: photoToDelete.path
        }),
      });

      const result = await response.json();
      console.log('Odpowiedź serwera po usunięciu:', result);

      if (!response.ok) {
        throw new Error(result.error || 'Błąd podczas usuwania zdjęcia');
      }

      // Aktualizacja listy zdjęć po usunięciu
      setUserPhotos(prev => {
        const updatedTaskPhotos = [...prev[taskId]];
        updatedTaskPhotos.splice(photoIndex, 1);

        return {
          ...prev,
          [taskId]: updatedTaskPhotos
        };
      });

    } catch (err) {
      console.error('Błąd usuwania zdjęcia:', err);
      alert('Wystąpił błąd podczas usuwania zdjęcia. Spróbuj ponownie.');
    }
  };

  // Wyświetlanie liczby zdjęć przy nagłówku zadania
  const getPhotoCountBadge = (taskId) => {
    const photos = userPhotos[taskId];
    if (!photos || photos.length === 0) return null;

    return (
      <span className="badge bg-secondary ms-2">
        {photos.length} {photos.length === 1 ? 'zdjęcie' :
                        (photos.length > 1 && photos.length < 5) ? 'zdjęcia' : 'zdjęć'}
      </span>
    );
  };

  // Jeśli użytkownik nie jest zalogowany, nie wyświetlaj zawartości
  if (!isAuthenticated) {
    return null; // Przekierowanie już nastąpi w useEffect
  }

  // Wyświetlanie ładowania
  if (loading) {
    return (
      <BackgroundLayout>
        <div className="text-center py-5 bg-light bg-opacity-75 rounded shadow">
          <Spinner animation="border" variant="secondary" />
          <p className="mt-3">Ładowanie zadań warsztatów fotograficznych...</p>
        </div>
      </BackgroundLayout>
    );
  }

  // Wyświetlanie błędu
  if (error) {
    return (
      <BackgroundLayout>
        <Alert variant="danger">
          <Alert.Heading>Wystąpił błąd!</Alert.Heading>
          <p>{error}</p>
          <Button variant="outline-danger" onClick={() => window.location.reload()}>
            Odśwież stronę
          </Button>
        </Alert>
      </BackgroundLayout>
    );
  }

  return (
    <BackgroundLayout>
      <Card className="bg-light bg-opacity-75 rounded shadow p-3">
        <Card.Body>
          {loginSuccess && (
            <Alert variant="success" dismissible onClose={() => setLoginSuccess(false)}>
              <Alert.Heading>Zalogowano pomyślnie!</Alert.Heading>
              <p>Zostałeś zalogowany przez link weryfikacyjny z emaila.</p>
            </Alert>
          )}

<Row className="align-items-center">
  <Col>
    <h1 className="m-0">Zadania</h1>
  </Col>
  <Col xs="auto">
    <img src={logo} alt="logo" className="img-fluid mb-2" style={{ maxHeight: '80px' }} />
  </Col>
</Row>

          {photosError && (
            <Alert variant="warning" className="mb-4">
              <p>{photosError}</p>
              <Button variant="outline-dark" size="sm" onClick={() => window.location.reload()}>
                Odśwież stronę
              </Button>
            </Alert>
          )}

          {tasks.length === 0 ? (
            <Alert variant="info">
              Brak dostępnych zadań warsztatowych.
            </Alert>
          ) : (
            <Accordion activeKey={activeKey} onSelect={(eventKey) => setActiveKey(eventKey)}>
              {tasks.map((task, index) => (
                <div key={task.id} className={index > 0 ? "mt-3" : ""}>
                  <Accordion.Item eventKey={task.id}>
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100 me-3">
                        <div>
                          <span className="h5 mb-0">{task.title}</span>
                          {getPhotoCountBadge(task.id)}
                        </div>
                        <span className="text-muted small">
                          {formatDate(task.date.start)} - {formatDate(task.date.end)}
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row className="mt-3 flex-column flex-md-row">
                        <Col xs={12} md={6}>
		      <div className="mb-4" dangerouslySetInnerHTML={{ __html: task.description }}></div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
                          <Button
                            variant="secondary"
                            onClick={() => openUploadModal(task.id)}
                            className="mb-3 btn-lg"
                            size="lg"
                          >
                            <i className="bi bi-cloud-upload me-2"></i>
                            Prześlij zdjęcia do zadania
                          </Button>
                        </Col>
                      </Row>

                      {/* Wyświetlanie komponentu galerii zdjęć bezpośrednio */}
                      {photosLoading ? (
                        <div className="text-center py-3">
                          <Spinner animation="border" size="sm" variant="secondary" />
                          <span className="ms-2">Ładowanie twoich zdjęć...</span>
                        </div>
                      ) : (
                        userPhotos[task.id] && userPhotos[task.id].length > 0 && (
                          <PhotoGallery
                            photos={userPhotos[task.id]}
                            onDeletePhoto={handleDeletePhoto}
                            taskId={task.id}
                            title="Twoje przesłane zdjęcia"
                          />
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              ))}
            </Accordion>
          )}

          {/* Modal do uploadowania plików */}
          <FileUploadModal
            show={showUploadModal}
            onHide={() => setShowUploadModal(false)}
            taskId={currentTaskId}
            onUploadSuccess={handleUploadSuccess}
          />
        </Card.Body>
      </Card>
    </BackgroundLayout>
  );
}

export default TasksPage;
