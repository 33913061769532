// src/pages/TrialogPage.js
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button, Alert, Spinner, Accordion, ProgressBar, Badge } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import axios from 'axios';
import ImageLightbox from '../components/ImageLightbox';
import BackgroundLayout from '../components/BackgroundLayout';

function TrialogPage() {
  const { isAuthenticated, userEmail } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [trialogGroups, setTrialogGroups] = useState([]);
  const [userTrialogs, setUserTrialogs] = useState([]);
  const [selectedTrialog, setSelectedTrialog] = useState(null);
  const [selectedTrialogData, setSelectedTrialogData] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [activeKey, setActiveKey] = useState(null); // All accordions closed by default

  // Fetch trialog configuration data
  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchTrialogData = async () => {
      try {
        setLoading(true);
        // Fetch main trialog configuration
        const trialogResponse = await axios.get('/trialog.json');
        const groups = trialogResponse.data.groups;
        setTrialogGroups(groups);

        // Fetch all trialog definitions
        const trialogDefinitions = await Promise.all(
          groups.map(groupFile => axios.get(`/${groupFile}`))
        );

        // Find trialogs where the current user is a participant
        const userTrialogList = trialogDefinitions
          .filter(response => {
            const participants = response.data.project.participants;
            return participants.some(participant => participant.email === userEmail);
          })
          .map(response => response.data);

        setUserTrialogs(userTrialogList);

        // If there are user trialogs, select the first one by default
        if (userTrialogList.length > 0) {
          setSelectedTrialog(userTrialogList[0]);
          setSelectedTrialogData(await fetchTrialogImages(userTrialogList[0].project.dir));
        }

        setLoading(false);
      } catch (err) {
        setError('Nie udało się wczytać danych trialogów: ' + err.message);
        setLoading(false);
      }
    };

    fetchTrialogData();
  }, [isAuthenticated, userEmail]);

  // Fetch trialog images for a specific directory
  const fetchTrialogImages = async (dirName) => {
    try {
      const response = await axios.get(`/api/trialog-status.php?dir=${dirName}`);

      if (response.data.success) {
        // Convert the images object to the format we need
        const imageStatus = {};
        for (const [imageId, exists] of Object.entries(response.data.images)) {
          if (exists) {
            imageStatus[imageId] = true;
          }
        }
        return imageStatus;
      }

      return {};
    } catch (err) {
      console.error("Error fetching trialog images:", err);
      return {};
    }
  };

  // Handle trialog selection
  const handleTrialogSelect = async (trialog) => {
    setSelectedTrialog(trialog);
    setSelectedTrialogData(await fetchTrialogImages(trialog.project.dir));
    setActiveKey(null); // Close all accordions when changing trialogs
  };

  // Handle image upload with progress tracking
  const handleImageUpload = async (event, imageId) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    formData.append('imageId', imageId);
    formData.append('trialogDir', selectedTrialog.project.dir);

    try {
      setUploadingImage(true);
      setUploadProgress(0);

      // Create XMLHttpRequest to track upload progress
      const xhr = new XMLHttpRequest();

      // Set up progress event listener
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(percentComplete);
        }
      });

      // Set up event listeners for completion, errors, and aborts
      xhr.addEventListener('load', async () => {
        if (xhr.status === 200) {
          // Success - refresh trialog data
          setUploadProgress(100);
          // Add slight delay to show 100% completion before resetting
          setTimeout(async () => {
            setSelectedTrialogData(await fetchTrialogImages(selectedTrialog.project.dir));
            setUploadingImage(false);
            setUploadProgress(0);
          }, 500);
        } else {
          // Server error
          setError(`Nie udało się przesłać zdjęcia: Błąd serwera (${xhr.status})`);
          setUploadingImage(false);
          setUploadProgress(0);
        }
      });

      xhr.addEventListener('error', () => {
        setError('Nie udało się przesłać zdjęcia: Błąd połączenia');
        setUploadingImage(false);
        setUploadProgress(0);
      });

      xhr.addEventListener('abort', () => {
        setError('Przesyłanie zdjęcia zostało przerwane');
        setUploadingImage(false);
        setUploadProgress(0);
      });

      // Open and send request
      xhr.open('POST', '/api/trialog.php');
      xhr.send(formData);
    } catch (err) {
      setError('Nie udało się przesłać zdjęcia: ' + err.message);
      setUploadingImage(false);
      setUploadProgress(0);
    }
  };

  // Find current user's ID in the selected trialog
  const getCurrentUserIdInTrialog = () => {
    if (!selectedTrialog) return null;

    const participant = selectedTrialog.project.participants.find(
      p => p.email === userEmail
    );

    return participant ? participant.id : null;
  };

  // Find which photos the current user needs to upload
  const getUserUploadablePhotos = () => {
    if (!selectedTrialog) return [];

    const userId = getCurrentUserIdInTrialog();
    if (!userId) return [];

    // Collect all photo IDs that should be uploaded by this user
    const userPhotoIds = [];

    selectedTrialog.project.stages.forEach(stage => {
      stage.photos.forEach(photo => {
        if (photo.authorId === userId) {
          userPhotoIds.push(photo.id);
        }
      });
    });

    return userPhotoIds;
  };

  // Check if a photo can be uploaded based on the sequence
  // A photo can only be uploaded if all preceding photos in its story are already uploaded
  const canUploadPhoto = (photoId, storySequence) => {
    if (!selectedTrialogData) return false;

    // Z0 is always considered available
    if (photoId === 'Z0') return true;

    const photoIndex = storySequence.indexOf(photoId);
    if (photoIndex <= 0) return true; // First photo after Z0 can always be uploaded

    // Check if previous photo in this sequence is available
    const previousPhotoId = storySequence[photoIndex - 1];
    return previousPhotoId === 'Z0' || selectedTrialogData[previousPhotoId];
  };

  // Get participant name by ID
  const getParticipantName = (participantId) => {
    if (!selectedTrialog) return participantId;

    const participant = selectedTrialog.project.participants.find(p => p.id === participantId);
    return participant ? participant.name : participantId;
  };

  // Render the trialog content with stories in accordion
  const renderTrialogContent = () => {
    if (!selectedTrialog) {
      return (
        <Alert variant="info">
          Nie znaleziono trialogów, w których bierzesz udział.
        </Alert>
      );
    }

    const { project } = selectedTrialog;
    const userPhotoIds = getUserUploadablePhotos();
    const userId = getCurrentUserIdInTrialog();

    return (
      <div>
	    {/*<h3>{project.title}</h3>
        <h5>{project.subtitle}</h5>*/}
        <h3><strong>Grupa:</strong> {project.group}</h3>

        {/* Uczestnicy trialogów */}
        <div className="mt-3 mb-4">
          <h3>Uczestnicy:</h3>
          <Row>
            {project.participants.map((participant) => (
              <Col key={participant.id} md={4} className="mb-2">
                <Card className={participant.id === userId ? 'border-primary' : ''}>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <Badge
                        bg={participant.id === userId ? 'primary' : 'secondary'}
                        className="me-2"
                      >
                        {participant.id}
                      </Badge>
                      <strong>{participant.name}</strong>
                      {participant.id === userId && (
                        <Badge bg="info" className="ms-2">Ty</Badge>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        <h3 className="mt-4">Historie fotograficzne</h3>
        <Accordion className="bg-secondary rounded accordion-flush p-md-5 p-sm-1 text-light" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
          {project.finalStories.map((story, index) => (
            <Accordion.Item className="my-2" key={story.storyNumber} eventKey={index.toString()}>
              <Accordion.Header>
		  <span className="h4">
                Historia {story.storyNumber} {story.title ? `: ${story.title}` : ''} </span>
                <span className="ms-2 text-muted">({story.sequence.join(' → ')})</span>
              </Accordion.Header>
              <Accordion.Body className="bg-dark">
                <Row>
                  {story.sequence.map((photoId, photoIndex) => {
                    const isUserPhoto = userPhotoIds.includes(photoId);
                    const isStartingImage = photoId === 'Z0';
                    const isUploaded = selectedTrialogData && (selectedTrialogData[photoId] || isStartingImage);
                    const canUpload = canUploadPhoto(photoId, story.sequence);
                    const photo = isStartingImage
                      ? { id: photoId, inspirationId: null }
                      : project.stages
                          .flatMap(stage => stage.photos)
                          .find(p => p.id === photoId);

                    // Determine the author of this photo
                    const authorId = isStartingImage ? null : photo?.authorId;
                    const authorName = authorId ? getParticipantName(authorId) : 'System';

                    const imageUrl = `/uploads/trialogs/${project.dir}/${isStartingImage ? project.startingImage.url : photoId + '.jpg'}`;
                    const groupName = `story-${story.storyNumber}`;
                    const uploadFormId = `upload-${photoId}-${index}`;

                    return (
                      <Col md={3} key={photoIndex}>
                        <Card className={isUserPhoto ? 'border-primary' : ''}>
                          <Card.Header className="mb-2">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>
                                {photoId}
                                {/*isUserPhoto && <span className="text-primary"> (Twoje zdjęcie)</span>*/}
                              </span>
                              {authorId && (
                                <Badge
                                  bg={authorId === userId ? 'primary' : 'secondary'}
                                  title={`Autor: ${authorName}`}
                                >
                                  {authorId}
                                </Badge>
                              )}
                            </div>
                          </Card.Header>
                          <Card.Body className="p-2">
                            {!isStartingImage && (
                              <div className="small text-muted mb-2 d-flex justify-content-between">
				    {/*<div>Inspiracja: {photo?.inspirationId}</div>*/}
                                <div>Autor: {authorName}</div>
                              </div>
                            )}

                            {isUploaded ? (
                              <div className="text-center">
                                <ImageLightbox
                                  src={imageUrl}
                                  alt={`Zdjęcie ${photoId} (Autor: ${authorName})`}
                                  groupName={groupName}
                                />
                              </div>
                            ) : (
                              <div className="text-center p-3 bg-light">
                                {isUserPhoto ? (
                                  <>
                                    <p className="mb-2">Twoje zdjęcie</p>
                                    <div>
                                      <input
                                        type="file"
                                        id={uploadFormId}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleImageUpload(e, photoId)}
                                        disabled={uploadingImage || !canUpload}
                                      />

                                      {uploadingImage && uploadFormId.includes(photoId) ? (
                                        <div className="mb-3">
                                          <ProgressBar
                                            now={uploadProgress}
                                            label={`${uploadProgress}%`}
                                            variant="primary"
                                            animated={uploadProgress < 100}
                                            className="mb-2"
                                          />
                                          <div className="text-center text-muted small">
                                            Przesyłanie zdjęcia...
                                          </div>
                                        </div>
                                      ) : (
                                        <Button
					        variant={uploadingImage || !canUpload ? "danger" : "success"}
                                          size="sm"
                                          onClick={() => document.getElementById(uploadFormId).click()}
                                          disabled={uploadingImage || !canUpload}
                                        >
                                          Prześlij zdjęcie
                                        </Button>
                                      )}

                                      {!canUpload && (
                                        <div className="mt-2">
                                          <small className="text-danger">
                                            Najpierw musi zostać przesłane poprzednie zdjęcie
                                          </small>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <p>Zdjęcie jeszcze nie zostało przesłane</p>
                                )}
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    );
  };

  // If user is not authenticated, show a message
  if (!isAuthenticated) {
    return (
      <Container className="py-4">
        <Alert variant="warning">
          Musisz być zalogowany, aby zobaczyć swoje trialogi.
        </Alert>
      </Container>
    );
  }

  return (
  <BackgroundLayout>
    <Container className="py-4 bg-light bg-opacity-75 rounded">
      <h1>Fotograficzny trialog</h1>
	  {/*
      <p className="lead">
        Wspólne tworzenie fotograficznych historii w grupach trzyosobowych.
      </p>*/}

      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      {loading ? (
        <div className="text-center py-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Ładowanie...</span>
          </Spinner>
          <p className="mt-2">Ładowanie danych trialogów...</p>
        </div>
      ) : (
        <Row>
          {userTrialogs.length > 0 ? (
            <>
              <Col md={12}>
                {renderTrialogContent()}
              </Col>
            </>
          ) : (
            <Col md={12}>
              <Alert variant="info">
                Nie znaleziono trialogów, w których bierzesz udział.
              </Alert>
            </Col>
          )}
        </Row>
      )}
    </Container>
  </BackgroundLayout>
  );
}

export default TrialogPage;
