// pages/VerifyPage.js - strona do weryfikacji kodu z linka w emailu
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Card, Alert, Spinner, Row, Col } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import BackgroundLayout from '../components/BackgroundLayout';

function VerifyPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { confirmAuth } = useContext(AuthContext);

  useEffect(() => {
    const code = searchParams.get('code');

    if (!code) {
      setError('Brak kodu weryfikacyjnego');
      setLoading(false);
      return;
    }

    const verifyCode = async () => {
      try {
        const response = await fetch(`/api/verify-code`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Nieprawidłowy kod weryfikacyjny');
        }

        // Potwierdzamy autentykację
        confirmAuth(data.email);
        setSuccess(true);

        // Przekierowanie na stronę z zadaniami po 1.5 sekundy
        setTimeout(() => navigate('/tasks', { replace: true }), 1500);
      } catch (error) {
        setError(error.message);
        // Przekierowanie do strony logowania po 3 sekundach
        setTimeout(() => navigate('/login', { replace: true }), 3000);
      } finally {
        setLoading(false);
      }
    };

    verifyCode();
  }, [searchParams, navigate, confirmAuth]);

  return (
    <BackgroundLayout>
      <div className="page-content">
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Card className="shadow">
              <Card.Header className="bg-secondary text-white">
                <h4 className="mb-0">Weryfikacja kodu</h4>
              </Card.Header>
              <Card.Body className="text-center py-5">
                {loading ? (
                  <div>
                    <Spinner animation="border" variant="secondary" />
                    <p className="mt-3 mb-0">Weryfikowanie kodu...</p>
                  </div>
                ) : error ? (
                  <Alert variant="danger">
                    <Alert.Heading>Błąd weryfikacji</Alert.Heading>
                    <p>{error}</p>
                    <p className="mt-2 mb-0">Przekierowanie do strony logowania...</p>
                  </Alert>
                ) : success ? (
                  <Alert variant="success">
                    <Alert.Heading>Weryfikacja udana</Alert.Heading>
                    <p>Zostałeś pomyślnie zalogowany.</p>
                    <p className="mb-0">Przekierowanie do zadań...</p>
                  </Alert>
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </BackgroundLayout>
  );
}

export default VerifyPage;
