// src/components/Navbar.js - Zaktualizowany komponent nawigacji z linkiem do trialogów
import React, { useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, Container, Button, Image } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
// Importuj ikonę z katalogu assets
import logo from '../assets/p25-50.png'; // Zakładając, że masz plik logo.png w katalogu assets

function Navbar() {
  const { isAuthenticated, userEmail, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const navbarToggleRef = useRef(null);

  const handleLogout = () => {
    logout();
    navigate('/');
    collapseMenu();
  };

  // Funkcja do zwijania menu
  const collapseMenu = () => {
    if (window.innerWidth < 992) { // Bootstrap lg breakpoint to 992px
      navbarToggleRef.current.click();
    }
  };

  return (
    <BootstrapNavbar bg="dark" variant="dark" expand="lg">
      <Container>
        <BootstrapNavbar.Brand as={Link} to="/" onClick={collapseMenu}>
          <Image
            src={logo}
            alt="Logo"
            className="d-inline-block align-top me-2"
            width="30"
            height="30"
          />
          Projekt25
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle ref={navbarToggleRef} aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" onClick={collapseMenu}>Start</Nav.Link>
            {isAuthenticated && (
              <>
                <Nav.Link as={Link} to="/tasks" onClick={collapseMenu}>Zadania</Nav.Link>
                <Nav.Link as={Link} to="/dialogs" onClick={collapseMenu}>Dialog</Nav.Link>
                <Nav.Link as={Link} to="/trialogs" onClick={collapseMenu}>Trialog</Nav.Link>
                {/*<Nav.Link as={Link} to="/protected" onClick={collapseMenu}>Strona chroniona</Nav.Link>*/}
              </>
            )}
            <Nav.Link as={Link} to="/about" onClick={collapseMenu}>O projekcie</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link as={Link} to="/contact" onClick={collapseMenu}>Kontakt</Nav.Link>
            {isAuthenticated ? (
              <>
                <Nav.Item className="d-flex align-items-center text-light me-3">
                  <i className="bi bi-person-circle me-1"></i> {userEmail}
                </Nav.Item>
                <Button variant="outline-light" onClick={handleLogout}>Wyloguj</Button>
              </>
            ) : (
              <Button variant="outline-light" as={Link} to="/login" onClick={collapseMenu}>Logowanie</Button>
            )}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
}

export default Navbar;
