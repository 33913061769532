// src/components/PhotoGallery.js
import React, { useState } from 'react';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';

/**
 * Komponent galerii zdjęć z funkcją lightbox i nawigacją między zdjęciami
 *
 * @param {Object} props
 * @param {Array} props.photos - Lista zdjęć do wyświetlenia
 * @param {Function} props.onDeletePhoto - Funkcja wywoływana przy usuwaniu zdjęcia
 * @param {String} props.taskId - ID zadania, którego dotyczą zdjęcia
 * @param {String} props.title - Tytuł dla sekcji ze zdjęciami
 */
function PhotoGallery({ photos, onDeletePhoto, taskId, title = "Twoje przesłane zdjęcia" }) {
  // Stan dla lightboxa
  const [showLightbox, setShowLightbox] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  // Stan dla modalu potwierdzenia usuwania
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState(null);

  // Otwieranie lightboxa
  const openLightbox = (index) => {
    setCurrentPhotoIndex(index);
    setShowLightbox(true);
  };

  // Zamykanie lightboxa
  const closeLightbox = () => {
    setShowLightbox(false);
  };

  // Nawigacja do poprzedniego zdjęcia
  const goToPrevious = () => {
    setCurrentPhotoIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  // Nawigacja do następnego zdjęcia
  const goToNext = () => {
    setCurrentPhotoIndex((prevIndex) =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Obsługa klawiszy strzałek
  const handleKeyDown = (event) => {
    if (!showLightbox) return;

    if (event.key === 'ArrowLeft') {
      goToPrevious();
    } else if (event.key === 'ArrowRight') {
      goToNext();
    } else if (event.key === 'Escape') {
      closeLightbox();
    }
  };

  // Funkcja pokazująca modal potwierdzenia usunięcia
  const confirmPhotoDelete = (taskId, index) => {
    setPhotoToDelete({ taskId, index });
    setShowDeleteConfirm(true);
  };

  // Funkcja obsługująca potwierdzenie usunięcia
  const handleDeleteConfirm = () => {
    if (photoToDelete && onDeletePhoto) {
      onDeletePhoto(photoToDelete.taskId, photoToDelete.index);
    }
    setShowDeleteConfirm(false);
    setPhotoToDelete(null);
  };

  // Funkcja anulująca usuwanie
  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false);
    setPhotoToDelete(null);
  };

  // Nasłuchiwanie klawiszy przy otwarciu lightboxa
  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showLightbox, currentPhotoIndex]);

  // Pobieranie URL miniatury
  const getThumbnailUrl = (photo) => {
    // Jeśli istnieje thumb_url w danych zdjęcia, użyj go
    if (photo.thumb_url) {
      return photo.thumb_url;
    }

    // W przeciwnym razie spróbuj utworzyć URL miniatury na podstawie ścieżki zdjęcia
    const photoPath = photo.path;
    const pathParts = photoPath.split('/');
    const fileName = pathParts.pop();

    // Utwórz ścieżkę do miniatury
    return [...pathParts, 'thumbs', fileName].join('/');
  };

  // Jeśli nie ma zdjęć, nie renderuj niczego
  if (!photos || photos.length === 0) {
    return null;
  }

  // Aktualne zdjęcie w trybie lightbox
  const currentPhoto = photos[currentPhotoIndex];

  // Nazwa zdjęcia do usunięcia (dla modalu potwierdzenia)
  const photoToDeleteName = photoToDelete ? photos[photoToDelete.index]?.name : "";

  return (
    <>
      <div className="mt-4 mb-3">
        <h5>{title} ({photos.length})</h5>
        <Row xs={1} md={2} lg={3} className="g-4">
          {photos.map((photo, index) => (
            <Col key={index}>
              <Card>
                <div className="position-relative thumbnail-container" style={{ height: '200px', backgroundColor: '#f8f9fa' }}>
                  <Card.Img
                    variant="top"
                    src={getThumbnailUrl(photo)}
                    alt={`Zdjęcie ${index + 1}`}
                    style={{
                      cursor: 'pointer',
                      maxHeight: '200px',
                      maxWidth: '100%',
                      width: 'auto',
                      height: 'auto',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      objectFit: 'contain'
                    }}
                    onClick={() => openLightbox(index)}
                  />
                  {onDeletePhoto && (
                    <Button
                      variant="danger"
                      size="sm"
                      className="position-absolute top-0 end-0 m-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmPhotoDelete(taskId, index);
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  )}
                </div>
                <Card.Body>
                  <Card.Title className="fs-6 text-truncate">
                    {photo.name}
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      {/* Lightbox Modal z nawigacją */}
      <Modal
        show={showLightbox}
        onHide={closeLightbox}
        size="xl"
        centered
        className="lightbox-modal"
        fullscreen="lg-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {currentPhoto?.name} <small className="text-muted">({currentPhotoIndex + 1}/{photos.length})</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 position-relative">
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
            {currentPhoto && (
              <img
                src={currentPhoto.url}
                alt={currentPhoto.name}
                className="img-fluid"
                style={{ maxHeight: '80vh', objectFit: 'contain' }}
              />
            )}

            {/* Przyciski nawigacji */}
            <Button
              variant="light"
              className="position-absolute start-0 top-50 translate-middle-y rounded-circle p-2 d-flex justify-content-center align-items-center"
              style={{ width: '3rem', height: '3rem', opacity: '0.7' }}
              onClick={goToPrevious}
            >
              <i className="bi bi-chevron-left fs-3"></i>
            </Button>

            <Button
              variant="light"
              className="position-absolute end-0 top-50 translate-middle-y rounded-circle p-2 d-flex justify-content-center align-items-center"
              style={{ width: '3rem', height: '3rem', opacity: '0.7' }}
              onClick={goToNext}
            >
              <i className="bi bi-chevron-right fs-3"></i>
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div>
            <Button variant="outline-dark" onClick={goToPrevious}>
              <i className="bi bi-arrow-left me-1"></i> Poprzednie
            </Button>
            <Button variant="outline-dark" className="ms-2" onClick={goToNext}>
              Następne <i className="bi bi-arrow-right ms-1"></i>
            </Button>
          </div>
          <Button variant="secondary-subtle" onClick={closeLightbox}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal potwierdzenia usunięcia */}
      <Modal
        show={showDeleteConfirm}
        onHide={handleDeleteCancel}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Potwierdź usunięcie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Czy na pewno chcesz usunąć zdjęcie <strong>{photoToDeleteName}</strong>?</p>
          <p className="text-danger">Tej operacji nie można cofnąć.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Anuluj
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Usuń zdjęcie
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PhotoGallery;
