// src/pages/ContactPage.js
import React from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faInstagram as fabInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';


function ContactPage() {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Tutaj można dodać logikę do obsługi formularza kontaktowego
    alert('Wiadomość została wysłana. Dziękujemy!');
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <h2 className="text-center mb-4">Skontaktuj się ze mną</h2>
          
          <Card className="shadow-sm mb-4">
            <Card.Body>
              <Card.Title>Piotr Bolek</Card.Title>
              <Card.Text>
                <strong>Email</strong> <Nav.Link as="a" className="my-link"
	  		href="mailto:piotr@bolek.waw.pl?Subject=Kontakt ze strony Projekt25"><FontAwesomeIcon icon={faEnvelope} /> piotr@bolek.waw.pl</Nav.Link><br />
                <strong>Strona</strong> <Nav.Link as="a" className="my-link"
	  		href="https://www.bolek.waw.pl"><FontAwesomeIcon icon={faGlobe} /> www.bolek.waw.pl</Nav.Link><br />
                <strong>Instagram</strong> <Nav.Link as="a" className="my-link"
	  		href="https://www.instagram.com/glon007"> <FontAwesomeIcon icon={fabInstagram} /> glon007 </Nav.Link>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactPage;
