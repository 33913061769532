// pages/LoginPage.js - z użyciem komponentu BackgroundLayout
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Card, Alert, Spinner, Row, Col } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import BackgroundLayout from '../components/BackgroundLayout';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showVerifyForm, setShowVerifyForm] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const { login, confirmAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Sprawdzenie czy w URL jest parametr error
    const queryParams = new URLSearchParams(location.search);
    const errorParam = queryParams.get('error');

    if (errorParam === 'invalid_code') {
      setError('Nieprawidłowy kod weryfikacyjny. Spróbuj ponownie.');
    }
  }, [location]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (!email) {
      setError('Wprowadź adres email');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch('/api/send-auth-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Wystąpił błąd podczas wysyłania emaila');
      }

      setSuccessMessage('Email z kodem weryfikacyjnym został wysłany. Sprawdź swoją skrzynkę pocztową.');
      setShowVerifyForm(true);
      login(email); // Zapisujemy email w kontekście
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifySubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!verificationCode) {
      setError('Wprowadź kod weryfikacyjny');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch('/api/verify-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: verificationCode }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Nieprawidłowy kod weryfikacyjny');
      }

      // Potwierdzamy autentykację
      confirmAuth(data.email);

      // Przekierowanie na stronę chronioną
      navigate('/tasks');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBackToEmail = () => {
    setShowVerifyForm(false);
    setSuccessMessage('');
    setError('');
  };

  return (
    <BackgroundLayout>
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card className="shadow p-4">
            <Card.Header className="bg-secondary text-white">
              <h4 className="mb-0">Logowanie</h4>
            </Card.Header>
            <Card.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              {successMessage && <Alert variant="success">{successMessage}</Alert>}

              {!showVerifyForm ? (
                <Form onSubmit={handleEmailSubmit}>
                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Adres Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Wprowadź swój adres email"
                      disabled={loading}
                      required
                    />
                    <Form.Text className="text-muted">
                      Na ten adres zostanie wysłany kod weryfikacyjny.
                    </Form.Text>
                  </Form.Group>

                  <div className="da-grid text-center">
                    <Button variant="secondary" type="submit" disabled={loading}>
                      {loading ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                          Wysyłanie...
                        </>
                      ) : 'Wyślij kod weryfikacyjny'}
                    </Button>
                  </div>
                </Form>
              ) : (
                <Form onSubmit={handleVerifySubmit}>
                  <Form.Group className="mb-3" controlId="formVerificationCode">
                    <Form.Label>Kod weryfikacyjny</Form.Label>
                    <Form.Control
                      type="text"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      placeholder="Wprowadź kod z emaila"
                      disabled={loading}
                      required
                    />
                    <Form.Text className="text-muted">
                      Wprowadź kod, który został wysłany na adres {email}
                    </Form.Text>
                  </Form.Group>

                  <div className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={handleBackToEmail} disabled={loading}>
                      Wróć
                    </Button>
                    <Button variant="secondary" type="submit" disabled={loading}>
                      {loading ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                          Weryfikacja...
                        </>
                      ) : 'Zweryfikuj kod'}
                    </Button>
                  </div>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </BackgroundLayout>
  );
}

export default LoginPage;
