// src/pages/AllDialogsPage.js
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button, Alert, Spinner, Badge } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import axios from 'axios';
import BackgroundLayout from '../components/BackgroundLayout';
import ImageLightbox from '../components/ImageLightbox';

function AllDialogsPage() {
  const { isAuthenticated, userEmail } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogGroups, setDialogGroups] = useState([]);
  const [allDialogs, setAllDialogs] = useState([]);
  const [selectedDialog, setSelectedDialog] = useState(null);

  // Fetch dialog images for a specific directory
  const fetchDialogImages = async (dirName) => {
    try {
      const response = await axios.get(`/api/dialog-status.php?dir=${dirName}`);

      if (response.data.success) {
        // Convert the images object to the format we need
        const imageStatus = {};
        for (const [imageId, exists] of Object.entries(response.data.images)) {
          if (exists) {
            imageStatus[imageId] = true;
          }
        }
        return imageStatus;
      }

      return {};
    } catch (err) {
      console.error("Error fetching dialog images:", err);
      return {};
    }
  };

  // Fetch all dialog data
  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchDialogData = async () => {
      try {
        setLoading(true);
        // Fetch main dialog configuration
        const dialogResponse = await axios.get('/dialog.json');
        const groups = dialogResponse.data.groups;
        setDialogGroups(groups);

        // Fetch all dialog definitions
        const dialogDefinitions = await Promise.all(
          groups.map(groupFile => axios.get(`/${groupFile}`))
        );

        // Get all dialogs
        const allDialogsList = dialogDefinitions.map(response => response.data);
        setAllDialogs(allDialogsList);

        // If there are dialogs, select the first one by default
        if (allDialogsList.length > 0) {
          setSelectedDialog(allDialogsList[0]);
          
          // Also fetch images for the first dialog
          const dialogImages = await fetchDialogImages(allDialogsList[0].project.dir);
          allDialogsList[0].images = dialogImages;
        }

        setLoading(false);
      } catch (err) {
        setError('Nie udało się wczytać danych dialogów: ' + err.message);
        setLoading(false);
      }
    };

    fetchDialogData();
  }, [isAuthenticated]);

  // Handle dialog selection
  const handleDialogSelect = async (dialog) => {
    // Fetch images for this dialog if we haven't yet
    if (!dialog.images) {
      dialog.images = await fetchDialogImages(dialog.project.dir);
    }
    
    setSelectedDialog(dialog);
  };

  // Check if user is participating in this dialog
  const isUserParticipating = (dialog) => {
    if (!dialog) return false;
    return dialog.project.participants.some(participant => participant.email === userEmail);
  };

  // Get participant name by ID
  const getParticipantName = (participantId, participants) => {
    if (!participants) return participantId;
    const participant = participants.find(p => p.id === participantId);
    return participant ? participant.name : participantId;
  };

  // Render dialogs list
  const renderDialogsList = () => {
    return (
      <div className="mb-4">
        <h2>Wszystkie dialogi</h2>
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>Grupa</th>
                <th>Tytuł</th>
                <th>Uczestnicy</th>
                <th>Liczba historii</th>
                <th>Status</th>
                <th>Akcje</th>
              </tr>
            </thead>
            <tbody>
              {allDialogs.map((dialog, index) => {
                const { project } = dialog;
                const isParticipating = isUserParticipating(dialog);
                
                return (
                  <tr key={index} className={isParticipating ? 'table-primary' : ''}>
                    <td>{project.group}</td>
                    <td>
                      {project.title}
                      {project.subtitle && <div><small>{project.subtitle}</small></div>}
                    </td>
                    <td>
                      {project.participants.map(p => (
                        <div key={p.id}>
                          <Badge 
                            bg={p.email === userEmail ? 'primary' : 'secondary'} 
                            className="me-1"
                          >
                            {p.id}
                          </Badge>
                          {p.name}
                          {p.email === userEmail && <Badge bg="info" className="ms-1">Ty</Badge>}
                        </div>
                      ))}
                    </td>
                    <td>{project.finalStories.length}</td>
                    <td>
                      {isParticipating ? (
                        <Badge bg="success">Uczestniczysz</Badge>
                      ) : (
                        <Badge bg="secondary">Nie uczestniczysz</Badge>
                      )}
                    </td>
                    <td>
                      <Button 
                        variant={selectedDialog === dialog ? "primary" : "outline-primary"} 
                        size="sm" 
                        onClick={() => handleDialogSelect(dialog)}
                      >
                        Szczegóły
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // Render the dialog details
  const renderDialogDetails = () => {
    if (!selectedDialog) {
      return (
        <Alert variant="info">
          Wybierz dialog z listy, aby zobaczyć szczegóły.
        </Alert>
      );
    }

    const { project } = selectedDialog;
    const isParticipating = isUserParticipating(selectedDialog);

    return (
      <div>
        <Card className="mb-4">
          <Card.Header className="bg-primary text-white">
            <h3 className="mb-0">{project.title}</h3>
            {project.subtitle && <div><small>{project.subtitle}</small></div>}
          </Card.Header>
          <Card.Body>
            <h4>Grupa: {project.group}</h4>
            
            {/* Status użytkownika */}
            {isParticipating ? (
              <Alert variant="success">
                Jesteś uczestnikiem tego dialogu. Możesz przesyłać zdjęcia w aplikacji Dialog.
              </Alert>
            ) : (
              <Alert variant="info">
                Nie jesteś uczestnikiem tego dialogu. Możesz tylko przeglądać jego strukturę.
              </Alert>
            )}

            {/* Uczestnicy dialogu */}
            <div className="mt-3 mb-4">
              <h4>Uczestnicy:</h4>
              <Row>
                {project.participants.map((participant) => (
                  <Col key={participant.id} md={4} className="mb-2">
                    <Card className={participant.email === userEmail ? 'border-primary' : ''}>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <Badge
                            bg={participant.email === userEmail ? 'primary' : 'secondary'}
                            className="me-2"
                          >
                            {participant.id}
                          </Badge>
                          <strong>{participant.name}</strong>
                          {participant.email === userEmail && (
                            <Badge bg="info" className="ms-2">Ty</Badge>
                          )}
                        </div>
                        <div className="small text-muted mt-1">
                          {participant.email}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>

            {/* Historie fotograficzne */}
            <h4 className="mt-4">Historie fotograficzne</h4>
            <div className="mb-4">
              {project.finalStories.map((story, index) => (
                <Card key={index} className="mb-3">
                  <Card.Header>
                    <h5>
                      Historia {story.storyNumber} {story.title ? `: ${story.title}` : ''}
                      <span className="ms-2 small text-muted">({story.sequence.join(' → ')})</span>
                    </h5>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex flex-wrap align-items-center">
                      {story.sequence.map((photoId, photoIndex) => {
                        const isStartingImage = photoId === 'Z0';
                        const isUploaded = isStartingImage || (selectedDialog.images && selectedDialog.images[photoId]);
                        
                        // Find the photo in the stages for author info
                        const authorId = isStartingImage ? null : 
                          project.stages
                            .flatMap(stage => stage.photos)
                            .find(p => p.id === photoId)?.authorId;
                        
                        const authorName = authorId 
                          ? getParticipantName(authorId, project.participants) 
                          : 'System';
                        
                        return (
                          <React.Fragment key={photoId}>
                            {photoIndex > 0 && (
                              <div className="mx-2 d-none d-md-block">
                                <i className="bi bi-arrow-right fs-3"></i>
                              </div>
                            )}
                            <div className="p-2" style={{ width: '200px' }}>
                              <Card className={`h-100 ${!isUploaded ? 'border-danger' : ''}`}>
                                <Card.Header className="p-1 text-center d-flex justify-content-between align-items-center">
                                  <Badge bg={isStartingImage ? "info" : "primary"}>
                                    {photoId}
                                  </Badge>
                                  {!isStartingImage && (
                                    <Badge bg="secondary">{authorId}</Badge>
                                  )}
                                </Card.Header>
                                <Card.Body className="p-2 text-center">
                                  {isUploaded ? (
                                    <>
                                      <ImageLightbox
                                        src={`/uploads/dialogs/${project.dir}/${isStartingImage ? project.startingImage.url : photoId + '.jpg'}`}
                                        alt={`Zdjęcie ${photoId} (Autor: ${authorName})`}
                                        groupName={`story-${story.storyNumber}`}
                                      />
                                      <div className="mt-2 small">
                                        <Badge bg={isStartingImage ? "info" : "secondary"}>
                                          {isStartingImage ? "Początek" : authorName}
                                        </Badge>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="text-danger pt-4 pb-4">
                                      <i className="bi bi-x-circle fs-2"></i>
                                      <p className="mt-2 small">Oczekiwanie</p>
                                    </div>
                                  )}
                                </Card.Body>
                              </Card>
                            </div>
                            {photoIndex < story.sequence.length - 1 && (
                              <div className="mx-2 d-md-none">
                                <i className="bi bi-arrow-down fs-3"></i>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  };

  // If user is not authenticated, show a message
  if (!isAuthenticated) {
    return (
      <Container className="py-4">
        <Alert variant="warning">
          Musisz być zalogowany, aby zobaczyć listę dialogów.
        </Alert>
      </Container>
    );
  }

  return (
    <BackgroundLayout>
      <Container className="py-4 bg-light bg-opacity-75 rounded">
        <h1>Przegląd wszystkich dialogów</h1>
        <p className="lead">
          Przeglądaj wszystkie zdefiniowane dialogi, niezależnie od Twojego udziału.
        </p>

        {error && (
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}

        {loading ? (
          <div className="text-center py-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Ładowanie...</span>
            </Spinner>
            <p className="mt-2">Ładowanie danych dialogów...</p>
          </div>
        ) : (
          <Row>
            {allDialogs.length > 0 ? (
              <Col md={12}>
                {renderDialogsList()}
                {renderDialogDetails()}
              </Col>
            ) : (
              <Col md={12}>
                <Alert variant="info">
                  Nie znaleziono żadnych dialogów w systemie.
                </Alert>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </BackgroundLayout>
  );
}

export default AllDialogsPage;
