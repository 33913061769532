// components/BackgroundLayout.js - komponent tła wielokrotnego użytku
import React from 'react';
import { Container } from 'react-bootstrap';
import backgroundImage from '../assets/DSCF8370.jpg';

function BackgroundLayout({ children }) {
  // Style dla kontenera z tłem
  const containerStyle = {
    minHeight: '100vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    //display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0'
  };

  return (
    <div style={containerStyle}>
      <Container>
        {children}
      </Container>
    </div>
  );
}

export default BackgroundLayout;
