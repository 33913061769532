// pages/HomePage.js - z użyciem komponentu BackgroundLayout
import React, { useContext } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import logo from '../assets/p25-200.png';
import BackgroundLayout from '../components/BackgroundLayout';

function HomePage() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleTasksClick = () => {
    navigate('/tasks');
  };

  return (
    <BackgroundLayout>
      <Row className="justify-content-center">
        <Col md={10} lg={8}>
          <div className="jumbotron py-5 px-4 bg-light bg-opacity-75 rounded shadow">
            <div className="d-flex flex-column flex-md-row justify-content-evenly align-items-center">
              <div className="mb-4 mb-md-0">
                <img src={logo} alt="logo" className="img-fluid" />
              </div>
              <div className="d-grid" style={{ minWidth: '200px' }}>
                {isAuthenticated ? (
                  <Button
                    variant="secondary"
                    size="lg"
                    onClick={handleTasksClick}
                    className="py-3 display-1 font-weight-bold"
                  >
                    <i className="bi bi-tools me-2"></i>
                    Zadania
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    size="lg"
                    as={Link}
                    to="/login"
                    className="py-3 display-1"
                  >
                    <i className="bi bi-box-arrow-in-right me-2"></i>
                    Zaloguj się
                  </Button>
                )}
              </div>
            </div>
            <hr className="my-4" />
            <p className="text-center h3 text-black">
              Projekt25 – „fotograficzne wieloświaty”
              <br />
	  <span className="h4">
	  	Jeśli jesteś uczestnikiem warsztatów <br/>
	  zaloguj się,
               aby uzyskać dostęp do zadań.</span>
            </p>
	  <hr/>
	  <p className="text-center h5 text-black">Jeśli nie bierzesz jeszcze udziału w warsztatach <br/>
	  <Button
	  variant="outline-dark"
	  as={Link}
	                      className="my-3 py-2"
	  to="https://bolek.waw.pl/warsztaty/">Zajrzyj na moją stronę</Button>
</p>
          </div>
        </Col>
      </Row>
    </BackgroundLayout>
  );
}

export default HomePage;
