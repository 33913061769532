// src/components/FileUploadModal.js
import React, { useState, useRef, useContext, useEffect } from 'react';
import { Modal, Button, Form, Alert, ProgressBar, Row, Col, Card } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';

const FileUploadModal = ({ show, onHide, taskId, onUploadSuccess }) => {
  // State management
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // References and context
  const fileInputRef = useRef(null);
  const { userEmail, isAuthenticated } = useContext(AuthContext);

  // State for drag and drop functionality
  const [isDragging, setIsDragging] = useState(false);

  // Generate image previews when files are selected
  useEffect(() => {
    if (files.length === 0) {
      setFilePreviews([]);
      return;
    }

    const newPreviews = [];
    let processedFiles = 0;

    const updatePreviewsState = () => {
      processedFiles++;
      if (processedFiles === files.length) {
        setFilePreviews(newPreviews);
      }
    };

    files.forEach(file => {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          newPreviews.push({
            file: file.name,
            preview: e.target.result,
            size: file.size
          });
          updatePreviewsState();
        };
        reader.readAsDataURL(file);
      } else {
        newPreviews.push({
          file: file.name,
          preview: null,
          size: file.size
        });
        updatePreviewsState();
      }
    });
  }, [files]);

  // Event handlers
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFiles(Array.from(e.target.files));
      setError(null);
    }
  };

  // Drag and drop handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files.length > 0) {
      const imageFiles = Array.from(e.dataTransfer.files).filter(
        file => file.type.startsWith('image/')
      );

      if (imageFiles.length > 0) {
        setFiles(imageFiles);
        setError(null);
      } else {
        setError('Proszę upuścić tylko pliki graficzne');
      }
    }
  };

  const resetForm = () => {
    setFiles([]);
    setFilePreviews([]);
    setUploadProgress(0);
    setIsUploading(false);
    setError(null);
    setSuccess(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClose = () => {
    resetForm();
    onHide();
  };

  const cancelUpload = () => {
    setIsUploading(false);
    setError('Przesyłanie zostało anulowane');
    // Here you would implement the XHR abort logic
  };

  const handleUpload = async () => {
    // Validation
    if (!isAuthenticated) {
      setError('Musisz być zalogowany, aby przesyłać zdjęcia');
      return;
    }

    if (files.length === 0) {
      setError('Wybierz pliki do przesłania');
      return;
    }

    // Reset states
    setIsUploading(true);
    setUploadProgress(0);
    setError(null);
    setSuccess(null);

    try {
      // Prepare form data
      const formData = new FormData();
      formData.append('taskId', taskId);
      formData.append('userEmail', userEmail);

      files.forEach(file => {
        formData.append('photos[]', file);
      });

      // Create and configure XMLHttpRequest for progress tracking
      const xhr = new XMLHttpRequest();

      // Progress event listener
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(percentComplete);
        }
      });

      // Load event listener
      xhr.addEventListener('load', () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const result = JSON.parse(xhr.responseText);

          const filesNoun = files.length === 1 ? 'plik' : 'pliki/ów';
          setSuccess(`Przesłano ${files.length} ${filesNoun}`);
          setIsUploading(false);

          // Handle successful upload
          if (onUploadSuccess && result.files && result.files.length > 0) {
            const uploadedFiles = result.files.map(file => ({
              name: file.name,
              path: file.path,
              url: file.url
            }));
            onUploadSuccess(taskId, uploadedFiles);
          }

          // Auto-close on success
          setTimeout(handleClose, 2000);
        } else {
          // Handle error responses
          let errorMessage = 'Wystąpił błąd podczas przesyłania plików';
          try {
            const response = JSON.parse(xhr.responseText);
            if (response.error) {
              errorMessage = response.error;
            }
          } catch (e) {
            console.error('Błąd parsowania odpowiedzi JSON:', e);
          }
          setError(errorMessage);
          setIsUploading(false);
        }
      });

      // Error event listener
      xhr.addEventListener('error', () => {
        setError('Błąd sieci podczas przesyłania plików');
        setIsUploading(false);
      });

      // Abort event listener
      xhr.addEventListener('abort', () => {
        setError('Przesyłanie zostało przerwane');
        setIsUploading(false);
      });

      // Send request
      xhr.open('POST', '/api/upload-photos');
      xhr.send(formData);

    } catch (err) {
      console.error('Błąd podczas przesyłania plików:', err);
      setError(err.message || 'Wystąpił nieoczekiwany błąd');
      setIsUploading(false);
    }
  };

  // Helper function to format file size
  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + ' B';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB';
    else return (bytes / 1048576).toFixed(1) + ' MB';
  };

  // Render file preview cards
  const renderFilePreviews = () => {
    if (filePreviews.length === 0) return null;

    return (
      <div className="mb-4">
        <h6 className="mb-3">Podgląd wybranych zdjęć ({filePreviews.length}):</h6>
        <Row xs={2} md={3} lg={4} className="g-3">
          {filePreviews.map((file, index) => (
            <Col key={index}>
              <Card className="h-100 thumbnail-card">
                <div className="thumbnail-container">
                  {file.preview ? (
                    <Card.Img
                      variant="top"
                      src={file.preview}
                      alt={file.file}
                      className="thumbnail-image"
                    />
                  ) : (
                    <div className="thumbnail-placeholder">
                      <i className="bi bi-file-earmark-image"></i>
                    </div>
                  )}
                </div>
                <Card.Body className="p-2">
                  <div className="small text-truncate" title={file.file}>
                    {file.file}
                  </div>
                  <div className="small text-muted">
                    {formatFileSize(file.size)}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  // Render progress indicator
  const renderProgress = () => {
    if (!isUploading) return null;

    return (
      <div className="mb-3">
        <p className="mb-1">Postęp przesyłania:</p>
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          variant="secondary"
          animated
        />
      </div>
    );
  };

  // Render alert messages
  const renderAlerts = () => (
    <>
      {error && (
        <Alert variant="danger" className="mb-3">
          {error}
        </Alert>
      )}
      {success && (
        <Alert variant="success" className="mb-3">
          {success}
        </Alert>
      )}
    </>
  );

  // Render footer buttons
  const renderFooterButtons = () => {
    if (isUploading) {
      return (
        <Button variant="danger" onClick={cancelUpload}>
          Anuluj przesyłanie
        </Button>
      );
    }

    return (
      <>
        <Button variant="outline-dark" onClick={handleClose}>
          Zamknij
        </Button>
        <Button
          variant="secondary"
          onClick={handleUpload}
          disabled={files.length === 0 || !isAuthenticated}
        >
          Prześlij zdjęcia
        </Button>
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop={isUploading ? 'static' : true}
      keyboard={!isUploading}
      size="lg"
    >
      <Modal.Header closeButton={!isUploading}>
        <Modal.Title>Prześlij zdjęcia do zadania</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!isAuthenticated ? (
          <Alert variant="warning">
            Musisz być zalogowany, aby przesyłać zdjęcia.
          </Alert>
        ) : (
          <>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Label>Dodaj zdjęcia do zadania:</Form.Label>

              <div
                className={`drop-zone ${isDragging ? 'active' : ''}`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="drop-zone-content">
                  <i className="bi bi-cloud-arrow-up-fill drop-icon"></i>
                  <p className="drop-text">
                    <strong>Przeciągnij i upuść zdjęcia tutaj</strong>
                    <span>lub</span>
                  </p>
                  <Button
                    variant="outline-dark"
                    onClick={() => fileInputRef.current.click()}
                    disabled={isUploading}
                  >
                    Wybierz pliki
                  </Button>
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    accept="image/*"
                    disabled={isUploading}
                    ref={fileInputRef}
                    className="visually-hidden"
                  />
                </div>
              </div>

              <Form.Text className="text-muted mt-2">
                Możesz wybrać wiele plików jednocześnie. Akceptowane formaty: JPG, PNG, GIF.
              </Form.Text>
            </Form.Group>

            {renderFilePreviews()}
            {renderProgress()}
            {renderAlerts()}
          </>
        )}

        {/* CSS styles for thumbnails and drop zone */}
        <style type="text/css">
          {`
          .thumbnail-container {
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            background-color: #f8f9fa;
          }
          .thumbnail-image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
          .thumbnail-placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: 2rem;
            color: #adb5bd;
          }
          .thumbnail-card {
            transition: transform 0.2s;
          }
          .thumbnail-card:hover {
            transform: scale(1.03);
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
          }

          /* Drop zone styles */
          .drop-zone {
            border: 2px dashed #dee2e6;
            border-radius: 8px;
            padding: 30px;
            text-align: center;
            background-color: #f8f9fa;
            transition: all 0.3s ease;
            cursor: pointer;
          }

          .drop-zone.active {
            border-color: #0d6efd;
            background-color: rgba(13, 110, 253, 0.05);
            transform: scale(1.01);
          }

          .drop-zone-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .drop-icon {
            font-size: 3rem;
            color: #6c757d;
            margin-bottom: 15px;
          }

          .drop-text {
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            gap: 5px;
          }

          .drop-text span {
            color: #6c757d;
            font-size: 0.9rem;
          }

          .visually-hidden {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
          }
          `}
        </style>
      </Modal.Body>

      <Modal.Footer>
        {renderFooterButtons()}
      </Modal.Footer>
    </Modal>
  );
};

export default FileUploadModal;
