// context/AuthContext.js - kontekst autentykacji
import { createContext } from 'react';

const AuthContext = createContext({
  isAuthenticated: false,
  userEmail: '',
  login: () => {},
  logout: () => {},
  confirmAuth: () => {}
});

export default AuthContext;


