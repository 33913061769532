// pages/ProtectedPage.js - chroniona strona, dostępna tylko po zalogowaniu
import React, { useContext } from 'react';
import { Card, Alert, Row, Col } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';

function ProtectedPage() {
  const { userEmail } = useContext(AuthContext);

  return (
    <div className="protected-page">
      <Alert variant="success" className="mb-4">
        <Alert.Heading>Zalogowany pomyślnie!</Alert.Heading>
        <p>
          Witaj <strong>{userEmail}</strong>! Masz teraz dostęp do chronionej części aplikacji.
        </p>
      </Alert>
      
      <h2 className="mb-4">Panel użytkownika</h2>
      
      <Row>
        <Col md={6} className="mb-4">
          <Card>
            <Card.Header>Informacje o koncie</Card.Header>
            <Card.Body>
              <Card.Title>Twoje dane</Card.Title>
              <Card.Text>
                <strong>Email:</strong> {userEmail}<br />
                <strong>Rola:</strong> Administrator<br />
                <strong>Status:</strong> Aktywny<br />
                <strong>Ostatnie logowanie:</strong> {new Date().toLocaleString()}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        
        <Col md={6} className="mb-4">
          <Card>
            <Card.Header>Aktywność</Card.Header>
            <Card.Body>
              <Card.Title>Ostatnie działania</Card.Title>
              <ul className="list-unstyled">
                <li className="mb-2">✓ Zalogowano pomyślnie</li>
                <li className="mb-2">✓ Zweryfikowano kod autoryzacyjny</li>
                <li className="mb-2">✓ Wysłano email z kodem weryfikacyjnym</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <Card className="mt-3">
        <Card.Header>Dostępne funkcje</Card.Header>
        <Card.Body>
          <p>Ta sekcja zawiera funkcje dostępne tylko dla zalogowanych użytkowników.</p>
          <Row className="text-center">
            <Col md={4} className="mb-3">
              <div className="p-3 bg-light rounded">
                <i className="bi bi-file-earmark-text fs-1 text-primary"></i>
                <h5 className="mt-2">Zarządzanie dokumentami</h5>
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div className="p-3 bg-light rounded">
                <i className="bi bi-people fs-1 text-primary"></i>
                <h5 className="mt-2">Zarządzanie użytkownikami</h5>
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div className="p-3 bg-light rounded">
                <i className="bi bi-gear fs-1 text-primary"></i>
                <h5 className="mt-2">Ustawienia konta</h5>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProtectedPage;

