// src/components/ImageLightbox.js
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// This component loads Lightbox2 from CDN and uses it to display images in fullscreen
const ImageLightbox = ({ src, alt, groupName, thumbnail }) => {
  // Initialize lightbox once when component mounts
  useEffect(() => {
    // Check if jQuery is available - Lightbox2 requires jQuery
    if (!window.jQuery) {
      // Load jQuery first if not available
      const jqueryScript = document.createElement('script');
      jqueryScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js';
      jqueryScript.async = true;
      document.body.appendChild(jqueryScript);

      jqueryScript.onload = loadLightbox;
    } else {
      loadLightbox();
    }

    function loadLightbox() {
      // Check if lightbox is already loaded
      if (!window.lightbox) {
        // Load lightbox CSS
        if (!document.querySelector('link[href*="lightbox.min.css"]')) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = 'https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.3/css/lightbox.min.css';
          document.head.appendChild(link);
        }

        // Load lightbox JS
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.3/js/lightbox.min.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = configureLightbox;
      } else {
        configureLightbox();
      }
    }

    function configureLightbox() {
      if (window.lightbox) {
        window.lightbox.option({
          'resizeDuration': 200,
          'wrapAround': true,
          'albumLabel': 'Zdjęcie %1 z %2',
          'fadeDuration': 300,
          'imageFadeDuration': 300,
          'alwaysShowNavOnTouchDevices': true,
          'disableScrolling': true,
          'showImageNumberLabel': true
        });
      }
    }

    // Clean up function
    return () => {
      // We don't remove the scripts/styles because they might be used by other instances
    };
  }, []);

  return (
    <a
      href={src}
      data-lightbox={groupName}
      data-title={alt}
      className="lightbox-image"
    >
      <img
        src={thumbnail || src}
        alt={alt}
        className="img-fluid"
        style={{ cursor: 'pointer' }}
      />
    </a>
  );
};

ImageLightbox.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  groupName: PropTypes.string,
  thumbnail: PropTypes.string
};

ImageLightbox.defaultProps = {
  alt: '',
  groupName: 'image',
  thumbnail: null
};

export default ImageLightbox;
