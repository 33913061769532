// src/App.js - Zaktualizowany komponent App z dodaną stroną Trialogów
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import VerifyPage from './pages/VerifyPage';
import ProtectedPage from './pages/ProtectedPage';
import TasksPage from './pages/TasksPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import TrialogPage from './pages/TrialogPage'; // Dodajemy nowy komponent
import DialogPage from './pages/DialogPage'; // Dodajemy nowy komponent
import Navbar from './components/Navbar';
import AuthContext from './context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// Import ikon Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faInstagram as fabInstagram } from '@fortawesome/free-brands-svg-icons';
import AllTrialogsPage from './pages/AllTrialogsPage';
import AllDialogsPage from './pages/AllDialogsPage';



function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  // Sprawdzenie czy użytkownik jest już zalogowany przy starcie aplikacji
  useEffect(() => {
    const savedEmail = localStorage.getItem('userEmail');
    const authStatus = localStorage.getItem('isAuthenticated');

    if (savedEmail && authStatus === 'true') {
      setIsAuthenticated(true);
      setUserEmail(savedEmail);
    }
  }, []);

  // Funkcja do logowania użytkownika
  const login = (email) => {
    setUserEmail(email);
  };

  // Funkcja potwierdzająca autentykację
  const confirmAuth = (email) => {
    setIsAuthenticated(true);
    setUserEmail(email);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('userEmail', email);
  };

  // Funkcja do wylogowania użytkownika
  const logout = () => {
    setIsAuthenticated(false);
    setUserEmail('');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userEmail');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userEmail, login, logout, confirmAuth }}>
      <Router>
        <div className="app d-flex flex-column min-vh-100">
          <Navbar />
          <main className="flex-grow-1">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route
                path="/login"
                element={isAuthenticated ? <Navigate to="/tasks" /> : <LoginPage />}
              />
              <Route
                path="/verify"
                element={<VerifyPage />}
              />
              <Route
                path="/protected"
                element={isAuthenticated ? <ProtectedPage /> : <Navigate to="/login" />}
              />
              <Route path="/tasks" element={<TasksPage />} />
              {/* Dodajemy nową ścieżkę do Trialogów */}
              <Route
                path="/trialogs"
                element={<TrialogPage />}
              />
	  {/*element={isAuthenticated ? <TrialogPage /> : <Navigate to="/login" />}*/}
              <Route
                path="/dialogs"
                element={<DialogPage />}
              />
	  {/* element={isAuthenticated ? <DialogPage /> : <Navigate to="/login" />} */}
		<Route path="/all-trialogs" element={<AllTrialogsPage />} />
		<Route path="/all-dialogs" element={<AllDialogsPage />} />
            </Routes>
          </main>
          <footer className="bg-dark py-3 text-center">
            <div className="container">
              <p className="text-light mb-2">© 2025 Piotr Bolek</p>
              <div className="d-flex justify-content-center gap-3 mb-2">
                <a href="mailto:piotr@bolek.waw.pl" className="text-light"><FontAwesomeIcon icon={faEnvelope} /> piotr@bolek.waw.pl</a>
                <a href="https://www.bolek.waw.pl" className="text-light" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGlobe} /> www.bolek.waw.pl</a>
                <a href="https://www.instagram.com/glon007" className="text-light" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={fabInstagram} /> Instagram
                </a>
              </div>
            </div>
          </footer>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
